<template lang="pug">
div.view_container
  Header
  div.view_title  Credits
  a(href="https://www.freepik.com/vectors/technology") Technology vector created by macrovector - www.freepik.com
  br
  br
  a(href="https://www.freepik.com/vectors/banner") Banner vector created by upklyak - www.freepik.com

</template>

<script>
/* global gtag */
import Header from '../components/Header'

export default {
  name: 'Attributions',
  components: {
    Header,
  },
  data() {
    return {

    }
  },
  methods: {

  },
  mounted: function () {
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/attributions'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.view_container {
  margin: 12px 8px 36px 8px;
  font-family: gibsonregular;
  font-size: 18px;
  color: #ADAAB6;
}

.view_container a {
  color: #ADAAB6;
  text-decoration: none;
}
.view_container a:hover {
  text-decoration: underline;
  color: #6e6d7a;
  text-align:  center;
}

</style>
